











import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({ components: { } })
export default class cButton extends Vue {
  @Prop({ default: false }) private primary!: boolean;

  @Prop({ default: false }) private circle!: boolean;

  @Prop({ default: false }) private shadow!: boolean;

  onClick(): void {
    this.$emit('click');
  }
}
